import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import styles from "./BookCard.module.scss";

export default function BookCard({ book }) {
  const { title, year, synopsis, image } = book;

  return (
    <Grid container>
      <Grid item xs={12} sm={4} lg={4} xl={4} className={styles.imageContainer}>
        <img src={image} alt={title} style={{ width: "90%", height: "100%" }} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        lg={8}
        xl={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}>
        <Grid>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "18px", sm: "18px", md: "18px", lg: "24px" },
              fontWeight: "600",
              textAlign: {
                xs: "center",
                sm: "inherit",
                md: "inherit",
                lg: "inherit",
              },
            }}
            lineHeight="40px">
            {title}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "16px",
              },
              fontWeight: "500",
              textAlign: {
                xs: "center",
                sm: "inherit",
                md: "inherit",
                lg: "inherit",
              },
            }}
            lineHeight="40px">
            <span style={{ fontWeight: "600" }}>Year:</span> {year}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "16px",
              },
              fontWeight: "500",
              textAlign: {
                xs: "center",
                sm: "inherit",
                md: "inherit",
                lg: "inherit",
              },
            }}
            lineHeight="40px">
            <span style={{ fontWeight: "600" }}>Synopsis:</span> {synopsis}
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            sx={{
              borderRadius: "4px",
              textTransform: "none",
              mt: 2,
              backgroundColor: "#FE982D",
              height: "50px",
              width: "80%",
              width: {
                xs: "100%",
                sm: "80%",
                md: "80%",
                lg: "80%",
              },
              color: "white",
            }}>
            <Typography
              fontFamily="Montserrat"
              sx={{
                fontSize: { xs: "16px", sm: "18", md: "18px", lg: "18px" },
                fontWeight: "600",
              }}
              lineHeight="34px">
              Buy Now
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
