import React from "react";
import styles from "./Filmography.module.scss";

import { Box, Button, Grid, Typography } from "@mui/material";
import MovieCard from "./MovieCard/MovieCard";

import Container from "@mui/material/Container";

import chattambikavalaImage from "../../assets/images/filmography/chattambiKavala.png";

import { movieData } from "../../utils/consts";
import { AdditionalMovieData } from "../../utils/consts";

export default function Filmography() {
  return (
    <div style={{ marginBottom: "280px" }}>
      <div className={styles.banner}></div>

      <Grid
        className="Filmography"
        sx={{ marginLeft: "8%", marginRight: "8%" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "3%",
            }}>
            <Typography
              fontFamily="TT Norms Pro Serif"
              sx={{
                fontSize: { xs: "22px", sm: "22px", md: "28px", lg: "30px" },
                fontWeight: "500",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "inherit",
                  lg: "inherit",
                },
              }}
              letterSpacing="10%"
              lineHeight="45.8px">
              SKT FILMOGRAPHY
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "1px",
            }}>
            <Typography
              fontFamily="TT Norms Pro Serif"
              sx={{
                fontSize: { xs: "15px", sm: "15px", md: "18px", lg: "20px" },
                fontWeight: "500",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "inherit",
                  lg: "inherit",
                },
              }}
              letterSpacing="10%">
              Detailed Yearly Chart of Sreekumaran Thampi's Movies
            </Typography>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid
            container
            spacing={5}
            sx={{
              marginTop: "10px",
            }}>
            {movieData.map((movie, index) => (
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <MovieCard
                  title={movie.title}
                  year={movie.year}
                  roles={movie.roles}
                  details={movie.details}
                  image={movie.image}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5%",
            }}>
            <Typography
              fontFamily="TT Norms Pro Serif"
              sx={{
                fontSize: { xs: "22px", sm: "22px", md: "28px", lg: "30px" },
                fontWeight: "500",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "inherit",
                  lg: "inherit",
                },
              }}
              letterSpacing="10%">
              ADDITIONAL FILMS AND CONTRIBUTIONS
            </Typography>
          </Grid>
          <Grid container spacing={5} sx={{ marginTop: "10px" }}>
            {AdditionalMovieData.map((movie, index) => (
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <MovieCard
                  title={movie.title}
                  year={movie.year}
                  roles={movie.roles}
                  details={movie.details}
                  image={movie.image}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
