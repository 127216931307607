import story1 from "../../../assets/images/story1.png";
import story2 from "../../../assets/images/story2.png";
import story3 from "../../../assets/images/story3.png";
import story4 from "../../../assets/images/story4.jpg";
import story5 from "../../../assets/images/story5.jpg";
import story6 from "../../../assets/images/story6.jpg";
import story7 from "../../../assets/images/story7.jpg";
import story8 from "../../../assets/images/story8.jpg";
import story9 from "../../../assets/images/story9.jpg";

export const booksArray = [
  {
    title: "Ente Kadha Nayikamar",
    image: story1,
  },
  {
    title: "Jeevitham Oru Pendulum",
    image: story2,
  },
  {
    title: "Karupum Veluppum Mayavarnangalum",
    image: story3,
  },
  // {
  //     title: 'Ente Kadha Nayikamar',
  //     image:story4
  // },

  // {
  //     title: 'Karupum Veluppum Mayavarnangalum',
  //     image:story5
  // },
  // {
  //     title: 'Ente Kadha Nayikamar',
  //     image:story6
  // },
  // {
  //     title: 'Jeevitham Oru Pendulum',
  //     image:story7
  // },
  // {
  //     title: 'Karupum Veluppum Mayavarnangalum',
  //     image:story8
  // },
  // {
  //     title: 'Jeevitham Oru Pendulum',
  //     image:story9
  // },
];
