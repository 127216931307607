import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import chattambikavalaImage from "../../../assets/images/filmography/chattambiKavala.png";
import filmelement from "../../../assets/images/filmography/filmelement.png";
import sampleimgae from "../../../assets/images/filmography/sample.png";

export default function MovieCard({ title, year, roles, details, image }) {
  return (
    <Card
      sx={{
        maxWidth: "518px",
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        marginTop: "10px",
      }}>
      <CardMedia
        component="img"
        image={image || sampleimgae}
        sx={{
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        }}
      />
      <CardContent sx={{ padding: 0, "&:last-child": { paddingBottom: 0 } }}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}>
          <img
            src={filmelement}
            alt=""
            style={{ width: "30px", height: "30px" }}
          />
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "18px", sm: "18px", md: "24px", lg: "30px" },
              fontWeight: "600",
              paddingLeft: "10px",
            }}
            letterSpacing="0%"
            lineHeight="40px">
            {year}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ margin: "20px 0", width: "100%" }} />
        </Grid>
        <Grid item>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "22px", sm: "22px", md: "24px", lg: "24px" },
              fontWeight: "600",
            }}
            letterSpacing="0%"
            lineHeight="40px">
            {title}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px" },
              fontWeight: "500",
            }}
            letterSpacing="0%"
            lineHeight="40px">
            <span style={{ fontWeight: "600" }}>Role(s): </span>
            {roles.join(", ")}
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "16px", sm: "16px", md: "16px", lg: "16px" },
              fontWeight: "500",
            }}
            letterSpacing="1%"
            textAlign="justify">
            <span style={{ fontWeight: "600" }}>Details: </span>
            {details}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
