import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import cont from "../../../assets/images/skt/contri.png";

const awards = [
  " 1. Kerala State Film Award for Best Lyricist (multiple times)",
  " 2. Kerala State Film Award for Best Screenplay",
  " 3. Kerala State Film Award for Second Best Film for Gaanam",
  " 4. Lifetime Achievement Awards from various cultural organizations",
];

const Contribution = () => {
  return (
    <Box sx={{ px: { xs: 4, sm: 6, md: 15 }, mt: {xs:4, sm:8, md:12}}}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
          <img src={cont} alt="" width="100%" />
        </Grid>
        <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 2, md:0 },
              textTransform: "uppercase",
            }}
          >
            Contributions to Literature
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", md: "14px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
            }}
          >
            Apart from his contributions to the film industry, Sreekumaran
            Thampi is also a respected figure in Malayalam literature. He has
            written several novels, short stories, and poems. His literary works
            reflect his deep understanding of human emotions and social issues.
            Thampi's eloquent writing style and insightful observations have
            earned him a dedicated readership.
          </Typography>
          {awards.map((award) => (
            <Typography
              fontFamily="Montserrat"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                fontWeight: 500,
                lineHeight: { xs: "24px", sm: "30px", md: "36px" },
                backgroundColor: "#F5F4ED",
                width: "100%",
                p: 0.5,
                mt: 1,
              }}
            >
              {award}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contribution;
