import React from "react";

import styles from "./MyBooks.module.scss";

import { Box, Button, Grid, Typography } from "@mui/material";
import RelevantBooks from "./RelevantBooks/RelevantBooks";
import BookCard from "./BookCard/BookCard";

import sampleimg from "../../assets/images/mybooks/sample.png";
import { booksData } from "utils/consts";

export default function MyBooks() {
  return (
    <div style={{ marginBottom: "280px" }}>
      <div container className={styles.banner}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "22px", sm: "22px", md: "28px", lg: "30px" },
              fontWeight: "500",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "inherit",
                lg: "inherit",
              },
            }}
            letterSpacing="10%">
            MY BOOKS
          </Typography>

          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "16px", sm: "18", md: "18px", lg: "22px" },
              fontWeight: "500",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "inherit",
                lg: "inherit",
              },
            }}
            letterSpacing="10%"
            lineHeight="45.8px"
            marginTop="-10px"
          >
            More Relevant Books
          </Typography>
        </Grid>
      </div>
      <div style={{ marginLeft: "8%", marginRight: "8%", marginTop: "100px" }}>
        <RelevantBooks />
      </div>
      {/* books */}

      <div
        style={{
          marginLeft: "8%",
          marginRight: "8%",
          marginTop: "100px",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "22px", sm: "22px", md: "22px", lg: "30px" },
              fontWeight: "600",
            }}
            letterSpacing="9%">
            List of Books by Sreekumaran Thampi
          </Typography>
          <Grid
            container
            sx={{
              marginTop: "50px",
              marginTop: {
                xs: "10px",
                sm: "10px",
                md: "52px",
                lg: "50px",
                xl: "50px",
              },
            }}
            spacing={2}>
            {booksData.map((book, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                lg={6}
                xl={6}
                key={index}
                marginTop="10px"
                sx={{
                  marginTop: {
                    xs: "50px",
                    sm: "30px",
                    md: "10px",
                    lg: "10px",
                    xl: "10px",
                  },
                }}>
                <BookCard book={book} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
