import gg from "../../../assets/images/Members/gg.jpg";
import skt from "../../../assets/images/Members/skt.png";
import jaya from "../../../assets/images/Members/jaya.png";
import jyothis from "../../../assets/images/Members/jyothis.png";
import shivan from "../../../assets/images/Members/shivan.png";
import gsv from "../../../assets/images/Members/gsv.png";
import sasi from "../../../assets/images/Members/sasi.png";
import vijayakumar from "../../../assets/images/Members/vijayakumar.png";
import faisalkhan from "../../../assets/images/Members/faisalkhan.png";

export const members = [
  {
    name: "Sreekumaran Thampi ",
    image: skt,
  },
  {
    name: "Gokulam Gopalan",
    image: gg,
  },
  {
    name: "Jaya Sekhar",
    image: jaya,
  },
  {
    name: "C. Sivankutty",
    image: shivan,
  },
  {
    name: "Kalliyoor Shashi",
    image: sasi,
  },
  {
    name: "GS Vijayan",
    image: gsv,
  },
  // {
  //   name: "Jyothis Chandran",
  //   image: jyothis,
  // },
  {
    name: "M Vijayakumar",
    image: vijayakumar,
  },
  {
    name: "Faisal khan",
    image: faisalkhan,
  },
];
