import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.scss";
import Logo from "../../assets/logos/logo-footer.png";
import Fb from "../../assets/icons/fb.png";
import Ig from "../../assets/icons/ig.png";
import Yt from "../../assets/icons/yt.png";
import In from "../../assets/icons/in.png";
import { Box } from "@mui/material";

function Footer() {
  return (
    <footer>
      <div className={style.top}>
        <div className={`${style.content} ${style.left}`}>
          <div className={style.sect2}>
            <Box sx={{ width: { xs: "300px", md: "350px" } }}>
              <img src={Logo} alt="" width="100%" height="auto" />
            </Box>
            <div className={style.footerDesc}>
            The primary mission of the Sreekumaran Thampi Foundation is to foster a vibrant cultural landscape and support the underprivileged sections of society through various initiatives.
            </div>
          </div>
        </div>
        <div className={style.pages}>
          <ul>
            <div className={style.sectionHeading}>Explore</div>
            <div className={style.sectionItems}>
              <div>
                <li className={style.sectionItems}>
                  <Link to="/">Home</Link>
                </li>
                {/* <li className={style.sectionItems}>
                  <Link to="/">Gallery</Link>
                </li> */}
                <li className={style.sectionItems}>
                  <Link to="/about_us">About Us</Link>
                </li>
                <li className={style.sectionItems}>
                  <Link to="/events">Events</Link>
                </li>
                <li className={style.sectionItems}>
                  <Link to="/filmography">Filmography</Link>
                </li>
                <li className={style.sectionItems}>
                  <Link to="/books">My Books</Link>
                </li>
                <li className={style.sectionItems}>
                  <Link to="/contact">Contact</Link>
                </li>
              </div>
            </div>
          </ul>

          <ul>
            <div className={style.sectionHeading}>Buy Books</div>

            <li className={style.sectionItems}>
              <a href="/">Ente Kadha Nayikamar</a>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Jeevitham Oru Pendulum</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Karupum Veluppum Mayavarnangalum</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Kuttanadu</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Aa Niyathiye Snehikkunnu</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Hridayathil Ninnu</Link>
            </li>
            <li className={style.sectionItems}>
              <Link to="/">Manjupoloru Penkutti</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={style.info}>
        <div className={style.socialMedia}>
          <Link to="/" target="_blank">
            <img src={Ig} alt="Ig" />
          </Link>
          <Link to="/" target="_blank">
            <img src={Fb} alt="fb" />
          </Link>
          <Link to="/" target="_blank">
            <img src={In} alt="in" />
          </Link>

          <Link to="/" target="_blank">
            <img src={Yt} alt="yt" />
          </Link>
        </div>
        <div className={style.copyright}>
          © 2024 SKT Foundation.. All rights reserved
        </div>
        <div className={style.legal}>
          <Link to="/terms">Terms & Conditions</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
