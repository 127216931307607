import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import style from "./Navbar.module.scss";
import clsx from "clsx";
import Hamburger from "hamburger-react";

import Logo from "../../assets/logos/logo.png";

const MENU_LIST = [
  { text: "Home", href: "/" },
  // { text: "Gallery", href: "/gallery" },
  { text: "About Us", href: "/about_us" },
  { text: "Events", href: "/events" },
  { text: "Filmography", href: "/filmography" },
  { text: "My Books", href: "/books" },
  { text: "Contact", href: "/contact" },
];

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const activeIndex = MENU_LIST.findIndex(
    (menu) => menu.href === location.pathname
  );
  const getLinkStyle = (idx) => {
    return idx === activeIndex
      ? { paddingBottom: "10px", fontFamily: 'Montserrat'}
      : {fontFamily: 'Montserrat'};
  };

  const [isMobile, setIsMobile] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  function handleResize() {
    const { innerWidth } = window;
    if (innerWidth < 1100) setIsMobile(true);
    else setIsMobile(false);
  }

  function toggleMenu() {
    setIsOpenMenu((prev) => !prev);
  }

  function closeMenu() {
    setIsOpenMenu(false);
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpenMenu) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "unset";
  }, [isOpenMenu]);

  const [position, setPosition] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY;

      setVisible(position > moving);
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const cls = visible ? "visible" : "hidden";
  return (
    <div className={style.wrapper}>
      <nav className={`${style.nav} ${style[cls]}`}>
        <a href={"/"}>
          <img alt="SKT Logo" src={Logo} width="200px" height="auto" />
        </a>

        {!isMobile && (
          <div className={style.desktopMenu}>
            {MENU_LIST.map((menu, idx) => (
              <div key={menu.text}>
                <Link to={menu.href} style={getLinkStyle(idx)}>
                  {menu.text}
                </Link>
              </div>
            ))}
          </div>
        )}

        {isMobile && (
          <>
            <div className={style.ham}>
              <Hamburger toggled={isOpenMenu} toggle={toggleMenu} />
            </div>

            <div
              className={clsx(
                style.menuList,
                style.mobile,
                isOpenMenu && style.open
              )}
            >
              {MENU_LIST.map((menu, idx) => (
                <div key={menu.text}>
                  <Link
                    to={menu.href}
                    className={
                      idx === activeIndex
                        ? `${style.navlink} ${style.mobActive}`
                        : style.navlink
                    }
                    onClick={() => {
                      setIsOpenMenu(false);
                    }}
                  >
                    {menu.text}
                  </Link>
                </div>
              ))}
            </div>
          </>
        )}
      </nav>
    </div>
  );
}
