import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  Button,
  CardContent,
} from "@mui/material";
import style from "./Books.module.scss";
import { createTheme, ThemeProvider } from "@mui/material";
import { booksArray } from "./booksArray";
import { useNavigate } from "react-router-dom";

const breakpoints = {
  values: {
    xs: 0,
    sm: 620,
    md: 1279,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const Books = () => {
  const navigate = useNavigate()
  const navigateTo = ()=>{
    navigate('/books')
  }
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper} sx={{ my: 12 }}>
        <Grid container sx={{ width: "100%", px: { xs: 4, md: 12 } }}>
          <Grid container>
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 1,
                  width: "100%",
                }}
              >
                <Typography
                  fontFamily="TT Norms Pro Serif"
                  sx={{
                    fontSize: { xs: "30px", md: "34px" },
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  MY BOOKS
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 1,
            }}
          >
            {booksArray.map((books, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    width: 345,
                    height: 520,
                    borderRadius: 0,
                    boxShadow: "none",
                  }}
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <CardMedia
                    component="img"
                    image={books.image}
                    loading="lazy"
                    alt="Chairman"
                    sx={{ height: 460, width: 345 }}
                  />
                  <CardContent sx={{ px: 0 }}>
                    <Typography
                      fontFamily="Montserrat"
                      fontWeight="500"
                      textAlign="start"
                      sx={{
                        fontSize: "15px",
                        fontWeight: 500,
                        color: "#000000",
                      }}
                    >
                      {books.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button onClick={navigateTo}
              variant="outlined"
              sx={{
                textTransform: "none",
                width: { xs: "160px", md: "180px" },
                height: { xs: "40px", md: "60px" },
                borderRadius: "60px",
                borderColor: "#000000",
                color: "#000000",
                mt: 3,
                fontSize: { xs: 12, md: 14, fontFamily: "Montserrat" },
              }}
            >
              View More Books
            </Button>
          </Box>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Books;
