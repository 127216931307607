import React from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { members } from "./members";
import styles from "./MeetTheTeam.module.scss";

const MeetTheTeam = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ width: "100%", }}>
        <div container className={styles.banner}>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              py:{xs:18},
              mb:-12
            }}
          >
            <Typography
              fontFamily="Tiempos Headline"
              sx={{
                fontSize: { xs: "18px", sm: "24px", md: "30px"},
                fontWeight: "600",
                textAlign: 'center',
                textTransform: "uppercase",
              }}
            >
              Meet the memebers
            </Typography>
          </Grid>
        </div>
        <Grid
          container
          spacing={3}
          sx={{
            mt: 1,
            px: { xs: 4, md: 12 },
          }}
        >
          {members.map((member, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Card
                sx={{
                  width: 345,
                  height: 520,
                  borderRadius: 0,
                  boxShadow: "none",
                }}
                style={{ backgroundColor: "#FFFFFF", boxShadow: "none" }}
              >
                <CardMedia
                  component="img"
                  image={member.image}
                  alt={member.name}
                  sx={{ height: 460, width: 345 }}
                />
                <CardContent sx={{ px: 0 }}>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight="500"
                    textAlign="start"
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#000000",
                    }}
                  >
                    {member.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MeetTheTeam;
