import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { BsArrowUpRight } from "react-icons/bs";
import foundation from "../../../assets/images/homepage/foundation.png";
import { useNavigate } from "react-router-dom";
const Foundation = () => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate("/about_us");
  };
  return (
    <Grid
      container
      sx={{
        p: { xs: 4, md: 10 },
        backgroundColor: "#F4F4F4",
        mt: 10,
        display: "flex",
        alignItems: "start",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        gap: { md: 2 },
      }}
    >
      <Grid item sx={{ width: { xs: "100%", md: "39%" } }}>
        <Box>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="TT Norms Pro Serif"
            fontSize="32px"
            fontStyle="normal"
            fontWeight="600"
          >
            Sreekumaran Thampi Foundation
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
            textAlign="justify"
          >
            The Sreekumaran Thampi Foundation stands as a testament to the
            enduring legacy of Sreekumaran Thampi. Through its diverse
            initiatives and unwavering commitment to the arts and social
            welfare, the foundation carries forward his vision of a culturally
            enriched and equitable society. By promoting the values he
            cherished, the foundation ensures that his contributions continue to
            inspire and benefit future generations.
          </Typography>
          <Button
            onClick={navigateTo}
            variant="outlined"
            sx={{
              textTransform: "none",
              width: { xs: "160px", md: "180px" },
              height: { xs: "40px", md: "60px" },
              borderRadius: "60px",
              borderColor: "#000000",
              color: "#000000",
              mt: 3,
              fontSize: { xs: 12, md: 14, fontFamily: "Montserrat" },
            }}
          >
            Explore More <BsArrowUpRight />
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          width: { xs: "100%", md: "58%" },
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
          mt: { xs: 4 },
        }}
      >
        <img src={foundation} alt="foundation" width="100%" />
      </Grid>
    </Grid>
  );
};

export default Foundation;
