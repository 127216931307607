import style from "./Banner.module.scss";

import { Box, createTheme, ThemeProvider } from "@mui/material";
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1000,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const Banner = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper} sx={{ height: "100vh" }}></Box>

      <Box className={style.tabletWrapper} sx={{ minHeight: "419px" }}></Box>

      <Box className={style.responsiveWrapper} sx={{ height: "780px" }}></Box>
    </ThemeProvider>
  );
};

export default Banner;
