import React from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import Chip from "@mui/material/Chip";

import eventimage from "../../../assets/images/events/eventimg1.png";
import calenderlogo from "../../../assets/images/events/calenderlogo.png";
import timelogo from "../../../assets/images/events/timelogo.png";
import maplogo from "../../../assets/images/events/maplogo.png";

import { CiCalendar } from "react-icons/ci";

export default function EventCard({ image, date, time, heading, location }) {
  return (
    <Grid container sx={{ backgroundColor: "#F8F8F8", padding: "10px" }}>
      <Grid item xs={12} sm={12} lg={12} xl={8}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            xl={4}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: {
                xs: "20px",
                sm: "20px",
                md: "23px",
                lg: "26px",
              },
            }}>
            <img alt=" Logo" src={image} height="auto" />
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            xl={8}
            sx={{
              display: {
                lg: "flex",
                md: "flex",
              },
              justifyContent: {
                lg: "center",
                md: "center",
              },
              alignItems: {
                lg: "center",
                md: "center",
              },
            }}>
            <Grid
              container
              spacing={2}
              sx={{
                marginTop: {
                  xs: "0px",
                  sm: "center",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                display: {
                  lg: "flex",
                  sm: "flex",
                },
                justifyContent: {
                  lg: "center",
                  sm: "center",
                },
                alignItems: {
                  lg: "center",
                  sm: "center",
                },
              }}>
              <Grid
                item
                xs={12}
                sx={{
                  display: {
                    xs: "flex",
                    sm: "flex",
                  },
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                  },
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    lg: "flex-start",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "center",
                  },
                }}>
                <Chip
                  icon={<img alt=" Logo" src={calenderlogo} height="auto" />}
                  label={date}
                  variant="outlined"
                  sx={{
                    "& .MuiChip-label": {
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                    },
                    padding: "5px",
                    width: "170px",
                    height: "45px",
                    borderRadius: "44px",
                  }}
                />
                <Chip
                  icon={<img alt=" Logo" src={timelogo} height="auto" />}
                  label={time}
                  variant="outlined"
                  sx={{
                    "& .MuiChip-label": {
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "16px",
                      fontFamily: "Montserrat",
                    },
                    padding: "5px",
                    width: "240px",
                    height: "45px",
                    borderRadius: "44px",
                    marginLeft: "10px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: {
                    xs: "flex",
                    sm: "flex",
                  },

                  justifyContent: {
                    xs: "center",
                    sm: "center",
                  },
                  alignItems: {
                    xs: "center",
                    sm: "center",
                  },
                }}>
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: {
                      xs: "20px",
                      sm: "20px",
                      md: "23px",
                      lg: "26px",
                    },
                    fontWeight: "600",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "inherit",
                    },
                    color: "#000000",
                  }}
                  lineHeight="40px">
                  {heading}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",

                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "flex-start",
                  },
                }}>
                <img alt=" Logo" src={maplogo} height="auto" />
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                    },
                    fontWeight: "500",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "inherit",
                    },
                    color: "#000000",
                  }}>
                  {location}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xl={4}
        sm={12}
        xs={12}
        lg={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: {
            xs: "0px",
            sm: "0px",
            md: "0px",
            lg: "0px",
            xl: "30px",
          },
          marginTop: {
            xs: "10px",
            sm: "20px",
            md: "20px",
            lg: "30px",
            xl: "0px",
          },

          justifyContent: {
            xs: "center",
            sm: "center",
            md: "center",
            lg: "center",
            xl: "flex-end",
          },
        }}>
        {" "}
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            borderRadius: "4px",
            backgroundColor: "#4288F8",
            fontSize: { xs: 12, md: 14 },
            "&:hover": {
              backgroundColor: "#4288F8",
              borderColor: "#4288F8",
              boxShadow: "none",
            },
          }}>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: {
                xs: "16px",
                sm: "24px",
                md: "24px",
                lg: "24px",
              },
              fontWeight: "600",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "inherit",
                lg: "inherit",
              },
              color: "#FFFFFF",
            }}
            lineHeight="">
            Buy Tickets
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
