import React from 'react'
import ContactBanner from './Banner/ContactBanner'
import GetInTouch from './GetInTouch/GetInTouch'

const ContactUS = () => {
  return (
    <div>
      <ContactBanner/>
      <GetInTouch/>
    </div>
  )
}

export default ContactUS
