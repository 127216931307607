import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const lyrics = [
  " 1. 'Hridayasarasile pranayapushpame' from the film Chandrakanta",
  " 2. 'Kasthuriman Kurunne' from the film Picnic",
  " 3. 'Sandhye Kannerithenthe' from the film Adimakal Udamakal",
  " 4. 'Swapnangal Swapnangale ningal' from the film Kanyakumari",
];

const LyricsCard = () => {
  return (
    <Box sx={{ px: { xs: 4, sm:6, md: 15 },  mt: {xs:4, sm:8, md:12}, }}>
      <Grid
        container
        sx={{
          p: 4,
          backgroundColor: "#F5F4ED",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderRadius: 2,
        }}
      >
        <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 0, md: 2 },
              textTransform: "uppercase",
            }}
          >
            Lyricist
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
            }}
          >
            Thampi's lyrical compositions have left an indelible mark on
            Malayalam cinema. His songs are known for their poetic beauty,
            emotional depth, and melodic charm. He has penned lyrics for over
            3,000 songs in more than 300 films. Some of his most memorable songs
            include:
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ width: { xs: "100%", md: "48%" }, mt: { xs: 2, sm: 2, md: 0 } }}
        >
          {lyrics.map((lyrics) => (
            <Typography
              fontFamily="Montserrat"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                fontWeight: 500,
                lineHeight: { xs: "24px", sm: "30px", md: "36px" },
                backgroundColor: "#FFFF",
                width: "100%",
                p: 2,
                mb: 1,
              }}
            >
              {lyrics}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default LyricsCard;
