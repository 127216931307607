import { AiOutlineLogout as LogOut } from "react-icons/ai";
import { FiUser } from "react-icons/fi";

//film images
import monhiniyattam from "../assets/images/filmography/moniniyattam.png";
import chandrakantham from "../assets/images/filmography/chandrakantham.png";
import thiruvonam from "../assets/images/filmography/thiruvonam.png";
import ethooruswapnam from "../assets/images/filmography/ethooruswapnam.png";
import venalilorumazha from "../assets/images/filmography/venalilorumazha.png";

import sampleimg from "../assets/images/mybooks/sample.png";

//relevent book data images
import entekadhanayikamar from "../assets/images/mybooks/entekadhanayikamar.png";
import jeevithamorupendulam from "../assets/images/mybooks/jeevithamorupendulam.png";
import karupumveluppum from "../assets/images/mybooks/karuppumveluppum.png";
import saradhaImg from "../assets/images/mybooks/sharadha.png";
import sheelaImg from "../assets/images/mybooks/sheela.png";
import jayabharathiImg from "../assets/images/mybooks/jayabharathi.png";
import srividyaImg from "../assets/images/mybooks/srividhya.png";
import kuttanad from "../assets/images/mybooks/kuttanad.png";

import munettam from "../assets/images/filmography/munettam.png";
import enikumorudivasam from "../assets/images/filmography/enikkumorudivasam.png";
import yuvajanolsavam from "../assets/images/filmography/yuvajanolsavam.png";
import bhandukkalshatrukkal from "../assets/images/filmography/bhandukkalshatrukkal.png";

export const Roles = {
  User: "user",
  Admin: "admin",
};

export const NavLinks = [
  { name: "My Account", link: "/profile/student/account", icon: <FiUser /> },
  { name: "Logout", link: "", icon: <LogOut /> },
];

export const movieData = [
  {
    title: "Chandrakaantham",
    year: "1974",
    roles: ["Director", "Writer"],
    details:
      "This was one of Thampi's early directorial ventures, showcasing his storytelling prowess and establishing him as a significant figure in Malayalam cinema.",
    image: chandrakantham,
  },
  {
    title: "Thiruvonam",
    year: "1975",
    roles: ["Director", "Writer", "Lyricist"],
    details:
      "Thampi showcased his versatility by not only directing and writing but also contributing the film's poignant lyrics.",
    image: thiruvonam,
  },

  {
    title: "Mohiniyattam",
    year: "1976",
    roles: ["Director", "Writer", "Lyricist"],
    details:
      "Thampi showcased his versatility by not only directing and writing but also contributing the film's poignant lyrics.",
    image: monhiniyattam,
  },
  {
    title: "Etho Oru Swapnam",
    year: "1978",
    roles: ["Director", "Writer", "Lyricist"],
    details:
      "A landmark film in Thampi's career, blending his lyrical genius with his directorial and writing skills to create a culturally rich narrative.",
    image: ethooruswapnam,
  },
  {
    title: "Venalil Oru Mazha",
    year: "1979",
    roles: ["Director", "Writer"],
    details:
      "Known for its strong narrative and character development, this film further solidified Thampi's reputation as a talented filmmaker.",
    image: venalilorumazha,
  },
  {
    title: "Munnettam",
    year: "1981",
    roles: ["Director", "Writer", "Lyricist"],
    details:
      "A film remembered for its touching story and memorable songs, showcasing Thampi's ability to weave lyrical beauty into his films.",
    image: munettam,
  },
  {
    title: "Enikkum Oru Divasam",
    year: "1982",
    roles: ["Director", "Writer"],
    details:
      "This comedy-drama demonstrated Thampi's versatility in handling different genres and his knack for engaging storytelling.",
    image: enikumorudivasam,
  },
  {
    title: "Yuvajanolsavam",
    year: "1986",
    roles: ["Director", "Writer"],
    details:
      "A film that is often cited for its emotional depth and compelling narrative, Yuvajanolsavam is a testament to Thampi's storytelling abilities.",
    image: yuvajanolsavam,
  },
  {
    title: "Bandhukkal Shathrukkal",
    year: "1993",
    roles: ["Director", "Writer"],
    details:
      "A gripping thriller, this film showcased Thampi's expertise in creating suspense and maintaining tension throughout the storyline.",
    image: bhandukkalshatrukkal,
  },
];

export const AdditionalMovieData = [
  // {
  //   title: "Tharattu",
  //   year: "1970",
  //   roles: ["Director", "Writer", "Lyricist"],
  //   details:
  //     "A film celebrated for its beautiful songs and touching story, adding to Thampi's legacy as a prolific lyricist and filmmaker.",
  // },
  // {
  //   title: "Rajavinte Makan",
  //   year: "1987",
  //   roles: ["Lyricist"],
  //   details:
  //     "Thampi's lyrical contributions to this film were widely appreciated and added emotional depth to the narrative.",
  // },
  // {
  //   title: "Marupuram",
  //   year: "1990",
  //   roles: ["Director", "Writer", "Lyricist"],
  //   details:
  //     "This film featured a strong storyline and impactful songs, further demonstrating Thampi's multifaceted talents.",
  // },
];

export const booksData = [
  {
    title: "Aa Niyathiye Snehikkunnu",
    year: 1975,
    synopsis:
      "A collection of poems that explore the themes of love, destiny, and the human experience. Thampi's lyrical prowess is evident in the beautifully crafted verses that resonate with deep emotions and philosophical reflections.",
    image: sampleimg,
  },
  {
    title: "Hridayathil Ninnu",
    year: 1982,
    synopsis:
      "This book is a compilation of Thampi's most celebrated song lyrics. It provides insights into the creative process behind his songwriting and includes anecdotes and stories related to the making of some of his most popular songs.",
    image: sampleimg,
  },
  {
    title: "Manjupoloru Penkutti",
    year: 1985,
    synopsis:
      "A novel that tells the poignant story of a young girl navigating the complexities of life and love. The narrative delves into her struggles, dreams, and the societal expectations that shape her journey.",
    image: sampleimg,
  },
  {
    title: "Kavyageethikal",
    year: 1990,
    synopsis:
      "Another collection of Thampi's poetry, this book focuses on themes such as nature, spirituality, and the essence of human relationships. The poems are known for their lyrical beauty and profound messages.",
    image: sampleimg,
  },
  {
    title: "Sarapancharam",
    year: 1993,
    synopsis:
      " A novel adaptation of Thampi's famous film of the same name. The story revolves around complex human relationships, love, betrayal, and redemption, capturing the essence of the movie in literary form.",
    image: sampleimg,
  },
  {
    title: "Swapnangal, Satyam, Cinema",
    year: 2000,
    synopsis:
      "This autobiographical work offers a glimpse into Thampi's life, his journey in the film industry, and his philosophical musings on dreams, reality, and the world of cinema. It includes personal anecdotes and reflections on his career.",
    image: sampleimg,
  },
  {
    title: "Thampiyude Kavithakal",
    year: 2005,
    synopsis:
      "A comprehensive collection of Thampi's poems, showcasing his evolution as a poet. The book covers a wide range of themes, from personal introspections to broader social issues, highlighting his versatility and depth as a poet.",
    image: sampleimg,
  },
  {
    title: "Ormakalude Ragini",
    year: 2010,
    synopsis:
      "A memoir that recounts Thampi's experiences in the film industry and his interactions with prominent figures in Malayalam cinema. The book is filled with nostalgic recollections and offers readers a behind-the-scenes look at the industry.",
    image: sampleimg,
  },
  {
    title: "Kavithakal",
    year: 2015,
    synopsis:
      " This book is another anthology of Thampi's poetry, focusing on the theme of love in its various forms. The poems are deeply emotional and capture the nuances of romantic relationships.",
    image: sampleimg,
  },
  {
    title: "Sangeetham, Snehapoorvam",
    year: 2018,
    synopsis:
      "A tribute to music, this book explores the significance of music in Thampi's life and career. It includes essays, song lyrics, and reflections on the transformative power of music.",
    image: sampleimg,
  },
  // Add more book objects as needed
];

export const relevantbookData = [
  {
    id: 1,
    title: "Ente Kadha Naayikamar",
    year: "2017",
    author: "Sreekumaran Thampi",
    synopsis:
      "Ente Kadha Naayikamar (translated as The Heroines of My Story) is a memoir by Sreekumaran Thampi that pays homage to the leading ladies of his films and his life. In this book, Thampi provides a fascinating and heartfelt account of the actresses who have played pivotal roles in his cinematic journey. Through personal anecdotes and reflective narratives, he brings to life the stories of these remarkable women, both on and off the screen.",
    image: entekadhanayikamar,
    highlights: [
      {
        title: "Profiles of Leading Actresses",
        description:
          "Thampi shares detailed profiles of the actresses who have worked with him in his numerous films. This includes their career beginnings, significant roles, and contributions to the Malayalam film industry.",
      },
      {
        title: "Behind-the-Scenes Stories",
        description:
          "The book offers a behind-the-scenes look at the making of some of Thampi's most iconic films. He recounts his interactions with these leading ladies, highlighting their professionalism, talent, and the unique qualities they brought to their roles.",
      },
      {
        title: "Personal Anecdotes",
        description:
          "Thampi includes personal stories and memories that shed light on his relationships with these actresses. These anecdotes provide a deeper understanding of the mutual respect and camaraderie that existed between them.",
      },
      {
        title: "Tributes and Reflections",
        description:
          '"Ente Kadha Naayikarnar" is also a tribute to the enduring impact these actresses have had on Thampi\'s life and career. He reflects on their performances, their contributions to his films, and their influence on his artistic vision.',
      },
      {
        title: "Cultural and Social Insights",
        description:
          "Through his reflections on the actresses and the roles they played, Thampi also offers insights into the cultural and social contexts of the times. He explores how these women navigated the challenges of the film industry and contributed to the evolving landscape of Malayalam cinema.",
      },
    ],
    actresses: [
      {
        name: "Sheela",
        image: sheelaImg,
        description:
          "Known for her powerful performances and enduring legacy in Malayalam cinema.",
      },
      {
        name: "Sharada",
        image: saradhaImg,
        description:
          "Celebrated for her versatility and depth in portraying complex characters.",
      },
      {
        name: "Jayabharathi",
        image: jayabharathiImg,
        description: "Renowned for her grace and strong screen presence.",
      },
      {
        name: "Srividya",
        image: srividyaImg,
        description:
          "Remembered for her expressive acting and significant contributions to the industry.",
      },
    ],

    conclusion:
      "Ente Kadha Naayikamar is a captivating and affectionate tribute to the heroines who have left an indelible mark on Sreekumaran Thampi's career and Malayalam cinema as a whole. The book is a must-read for fans of Thampi's work and for those interested in the history of Malayalam film. It offers a rich tapestry of stories and insights that celebrate the talent, dedication, and impact of these remarkable actresses.",
  },
  {
    id: 2,
    title: "Jeevitham Oru Pendulum",
    year: "2014",
    author: "Sreekumaran Thampi",
    synopsis: `Jeevitham Oru Pendulum" (translated as "Life is a Pendulum") is a reflective and philosophical work by Sreekumaran Thampi that delves into the complexities and dualities of life. In this book, Thampi explores the oscillating nature of human existence, drawing parallels between the pendulum's swing and life's constant motion between joy and sorrow, success and failure, and other contrasting experiences.`,
    image: jeevithamorupendulam,
    highlights: [
      {
        title: "Philosophical Reflections",
        description:
          "Thampi shares his deep philosophical insights on life, inspired by his own experiences and observations. He discusses how life, like a pendulum, is in constant motion, and how individuals can find balance amidst this perpetual swing.",
      },
      {
        title: "Personal Anecdotes",
        description:
          "The book includes numerous personal anecdotes from Thampi's life, providing a glimpse into his journey, the challenges he faced, and the lessons he learned. These stories add a personal touch and make the philosophical musings more relatable.",
      },
      {
        title: "Cinematic and Literary Influences",
        description:
          "Thampi reflects on how his work in cinema and literature has influenced his worldview. He discusses the impact of his creative endeavors on his understanding of life and the human condition.",
      },
      {
        title: "Exploration of Human Emotions",
        description:
          "The book delves into a wide range of human emotions, from love and happiness to pain and despair. Thampi's eloquent writing captures the essence of these emotions and their role in shaping our lives.",
      },
      {
        title: "Life Lessons and Wisdom",
        description:
          "Thampi offers valuable life lessons and wisdom, encouraging readers to embrace both the highs and lows of life. He emphasizes the importance of resilience, self-awareness, and finding meaning in every experience.",
      },
    ],

    themes: [
      {
        title: "Duality of Life:",
        description:
          "The central theme of the book is the dual nature of life and how individuals navigate the oscillations between extremes.",
      },
      {
        title: "Resilience and Adaptability:",
        description:
          "Thampi highlights the importance of resilience and adaptability in facing life's challenges",
      },
      {
        title: "Search for Balance:",
        description:
          " The book encourages readers to find balance and harmony amidst life's constant changes.",
      },
    ],
    conclusion: `"Jeevitham Oru Pendulum" is a profound and thought-provoking book that offers readers a deeper understanding of life's complexities. Through his philosophical reflections and personal stories, Sreekumaran Thampi provides a rich tapestry of insights that inspire readers to embrace the full spectrum of human experiences. This book is a testament to Thampi's wisdom and his ability to connect with readers on a deeply emotional and intellectual level.`,
  },
  {
    id: 3,
    title: "Karupum Veluppum Mayavarnangalum",
    year: "2018",
    author: "Sreekumaran Thampi",
    synopsis:
      "Karupum Veluppum Mayavarnangalum (translated as 'Black, White, and Illusory Colors') is a compelling work by Sreekumaran Thampi that explores the intricate shades of human life and the various facets of existence. The book delves into the contrasts and illusions that define our experiences, relationships, and perceptions.",
    image: karupumveluppum,
    highlights: [
      {
        title: "Exploration of Human Nature",
        description:
          "Thampi examines the complexities of human nature, discussing how people embody both light and darkness, truth and illusion. He uses a variety of characters and scenarios to illustrate these contrasts.",
      },
      {
        title: "Philosophical Insights",
        description:
          "The book is rich with philosophical musings on the nature of reality, morality, and the human condition. Thampi reflects on how our perceptions shape our understanding of the world and our place within it.",
      },
      {
        title: "Narrative Style",
        description:
          "Thampi's narrative is both poetic and introspective, blending storytelling with deep philosophical reflections. His eloquent writing style draws readers into a contemplative journey through life's myriad colors.",
      },
      {
        title: "Cultural and Social Commentary",
        description:
          "Thampi offers commentary on contemporary social issues, examining how societal norms and values influence individual behavior and societal dynamics. He discusses themes such as justice, inequality, and the pursuit of happiness.",
      },
      {
        title: "Personal Reflections",
        description:
          "Interwoven with the broader themes are Thampi's personal reflections and anecdotes, providing a personal touch and making the philosophical discussions more relatable.",
      },
    ],
    themes: [
      {
        title: "Duality and Contrasts",
        description:
          "The book emphasizes the dual nature of life—good and evil, truth and deception, light and darkness—examining how individuals navigate through these contradictions.",
      },
      {
        title: "Illusions and Reality",
        description:
          "Thampi explores the concept of Maya (illusion) and how it shapes our perceptions of truth and reality.",
      },
      {
        title: "Human Experience",
        description:
          "The book delves into the depths of human emotions, relationships, and experiences, highlighting how they are influenced by both internal and external factors.",
      },
    ],
    notable_sections: [
      {
        title: "Reflections on Morality",
        description:
          "Thampi discusses the complexities of moral choices and the grey areas that often define ethical dilemmas.",
      },
      {
        title: "Perceptions of Truth",
        description:
          "A section dedicated to understanding how individuals perceive truth and the subjective nature of reality.",
      },
      {
        title: "Stories of Contrasts",
        description:
          "Thampi uses various short stories and character studies to illustrate the themes of contrast and duality.",
      },
    ],
    conclusion:
      "Karupum Veluppum Mayavarnangalum is a profound and thought-provoking book that invites readers to contemplate the many shades of life. Thampi's poetic and philosophical narrative offers deep insights into the human condition, making it a valuable addition to his literary works.",
  },
  {
    id: 4,
    title: "Kuttanadu",
    year: "2020",
    author: "Sreekumaran Thampi",
    synopsis:
      "Kuttanadu is a vivid and evocative portrayal of the Kuttanad region in Kerala, known for its lush paddy fields, backwaters, and unique cultural heritage. In this book, Sreekumaran Thampi delves into the rich history, traditions, and everyday life of this picturesque region, weaving together personal anecdotes, cultural observations, and social commentary.",
    image: kuttanad,
    highlights: [
      {
        title: "Cultural Heritage",
        description:
          "Thampi explores the cultural heritage of Kuttanad, highlighting its traditional art forms, festivals, and local customs. He provides a detailed account of their evolution over time and their significance in the lives of the people.",
      },
      {
        title: "Historical Insights",
        description:
          "The book includes historical narratives that trace the development of Kuttanad, from ancient times to the present day, discussing significant events and influential figures.",
      },
      {
        title: "Personal Anecdotes",
        description:
          "Thampi shares nostalgic memories of his childhood and youth spent in Kuttanad, offering a personal perspective that adds depth and authenticity to the narrative.",
      },
      {
        title: "Environmental Beauty",
        description:
          "Thampi vividly describes the natural beauty of Kuttanad, with its expansive paddy fields, serene backwaters, and vibrant flora and fauna.",
      },
      {
        title: "Social Commentary",
        description:
          "Thampi provides insightful commentary on the social and economic aspects of life in Kuttanad, addressing challenges such as agriculture, water management, and economic development.",
      },
      {
        title: "Characters and Stories",
        description:
          "The book is enriched with stories of local characters, representing different facets of life in Kuttanad, bringing the region's culture and society to life.",
      },
    ],
    themes: [
      {
        title: "Nostalgia and Memory",
        description:
          "The book evokes a deep sense of nostalgia, as Thampi reminisces about his past experiences in Kuttanad and the importance of preserving cultural memories.",
      },
      {
        title: "Nature and Environment",
        description:
          "Thampi emphasizes the close connection between the people of Kuttanad and their natural environment, portraying how nature shapes their way of life.",
      },
      {
        title: "Cultural Identity",
        description:
          "Kuttanadu reflects on the formation of cultural identity through the interaction of traditions, history, and the environment in the region.",
      },
    ],
    notable_sections: [
      {
        title: "Festivals of Kuttanad",
        description:
          "A detailed description of the region's vibrant festivals, including Onam and boat races, and their cultural significance.",
      },
      {
        title: "Agricultural Practices",
        description:
          "Insights into the traditional and modern agricultural practices in Kuttanad, known as the 'Rice Bowl of Kerala.'",
      },
      {
        title: "Life on the Backwaters",
        description:
          "Stories and anecdotes about life on the backwaters, showcasing the unique lifestyle of local fishermen and boatmen.",
      },
    ],
    conclusion:
      "Kuttanadu is a heartfelt tribute to one of Kerala's most enchanting regions. Through his eloquent writing and deep personal connection, Thampi brings the beauty, culture, and spirit of Kuttanad to life, making it a valuable addition to his literary works.",
  },
];
