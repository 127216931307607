import eventimg1 from "../assets/images/events/eventimg1.png";

export const eventData = [
  // {
  //   id: 1,
  //   image: eventimg1,
  //   date: "23 Dec 2024",
  //   time: "11:00 AM to 3:00 PM",
  //   description:
  //     "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  //   location: "1086 Kincheloe Road, Portland, OR 97205",
  // },
  // {
  //   id: 2,
  //   image: eventimg1,
  //   date: "30 Dec 2024",
  //   time: "5:00 PM to 9:00 PM",
  //   description:
  //     "Another great event with exciting activities and entertainment.",
  //   location: "200 Main Street, Seattle, WA 98101",
  // },
  // {
  //   id: 2,
  //   image: eventimg1,
  //   date: "30 Dec 2024",
  //   time: "5:00 PM to 9:00 PM",
  //   description:
  //     "Another great event with exciting activities and entertainment.",
  //   location: "200 Main Street, Seattle, WA 98101",
  // },
];
