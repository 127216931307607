import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import rect1 from "../../../assets/images/Rectangle 14.png";
import rect2 from "../../../assets/images/Rectangle 15.png";
import rect3 from "../../../assets/images/Rectangle 16.png";
import icon from "../../../assets/icons/upright.png";
import { useNavigate } from "react-router-dom";

const imageArray = [rect1, rect2, rect3];

const LaunchEvent = () => {
  
  const navigate = useNavigate()
  const navigateTo = ()=>{
    navigate('/events')
  }

  return (
    <Grid container>
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          px: { xs: 4, md: 10 },
          mt: { xs: 6, md: 10 },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "40%" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: 20, md: 30, fontWeight: 600 },
              mt: { xs: 2 },
              textTransform: "uppercase",
            }}
          >
            Launch event
          </Typography>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
            textAlign='justify'
            sx={{ fontSize: { xs: 12, md: 14 } }}
          >
            Since its inception, the Sreekumaran Thampi Foundation has made a
            significant impact on the cultural and social landscape of Kerala.
            By supporting various artistic endeavors and social welfare
            projects, the foundation has touched the lives of many and continues
            to inspire future generations.
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "40%" } }}>
          <Typography
            fontFamily="Montserrat"
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
            textAlign='justify'
            sx={{ fontSize: { xs: 12, md: 14 } }}
          >
            The foundation undertakes various social welfare projects aimed at
            improving the living conditions of the underprivileged. This
            includes healthcare camps, educational support programs, and
            initiatives to provide basic necessities to those in need.
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3 }}>
            <Button
              variant="outlined"
              onClick={navigateTo}
              sx={{
                textTransform: "none",
                width: { xs: "120px", md: "160px" },
                height: { xs: "40px", md: "60px" },
                borderRadius: "60px",
                borderColor: "#000000",
                color: "#000000",
                fontSize: { xs: 12, md: 14, fontFamily: "Montserrat" },
              }}
            >
              Explore More
            </Button>
            <Box
              sx={{
                width: {
                  xs: "40px",
                  md: "63px",
                  display: "flex",
                  alignItems: "center",
                },
              }}
            >
              <img src={icon} alt="" width="100%" />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        sx={{
          display: { xs: "none", md: "flex" },
          justifyContent: "center",
          alignItems: "center",
          px: 10,
          gap: 4,
          mt: 4,
          width: "100%",
        }}
      >
        {imageArray.map((image) => (
          <img src={image} alt="" height="480px" />
        ))}
      </Grid>
      <Grid
        item
        sx={{
          display: { md: "none", xs: "flex" },
          flexDirection: "column",
          justifyContent: "center",
          px: 4,
          mt: 4,
          width: "100%",
        }}
      >
        <img src={rect1} alt="" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            mt: 2,
          }}
        >
          <img src={rect2} alt="" width="48%" />
          <img src={rect3} alt="" width="48%" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default LaunchEvent;
