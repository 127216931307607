import React, { useState } from "react";
import { FormControl, Box, Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Textarea from "@mui/joy/Textarea";
import { useFormik } from "formik";
import * as Yup from "yup";

//Form schema
const formSchema = Yup.object({
  fullName: Yup.string()
    .min(3, "Too Short!")
    .max(20, "Too Long!")
    .required("Full Name Required"),
  email: Yup.string().email("Invalid email").required("Email ID Required"),
  phoneNumber: Yup.string()
    .min(10, "Too Short!")
    .max(50, "Too Long!")
    .required("Phone Number Required"),
  message: Yup.string()
    .min(5, "Too Short!")
    .max(50, "Too Long!")
    .required("Message Required"),
});

const commonStyles = {
  width: "100%",
  backgroundColor: "#F7F7F7",
  "& label": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: { xs: 12, md: 16 },
  },
  "& input": {
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "#000000",
    fontSize: { xs: 12, md: 16 },
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  mb: 2,
};

const Form = () => {
  // formik
  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    onSubmit: async (values) => {
      const formData = {
        fullName: values.fullName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        message: values.message,
      };
    },
    validationSchema: formSchema,
  });

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }}>
        <Typography sx={{ color: "#fc0303", fontFamily: "Montserrat" }}>
          {formik.touched.fullName && formik.errors.fullName}
        </Typography>
        <TextField
          label="Name"
          sx={commonStyles}
          value={formik.values.fullName}
          onChange={formik.handleChange("fullName")}
          onBlur={formik.handleBlur("fullName")}
        />

        <Typography sx={{ color: "#fc0303", fontFamily: "Montserrat" }}>
          {formik.touched.email && formik.errors.email}
        </Typography>
        <TextField
          value={formik.values.email}
          onChange={formik.handleChange("email")}
          onBlur={formik.handleBlur("email")}
          label="Email"
          sx={commonStyles}
        />

        <Typography sx={{ color: "#fc0303", fontFamily: "Montserrat" }}>
          {formik.touched.phoneNumber && formik.errors.phoneNumber}
        </Typography>
        <TextField
          value={formik.values.phoneNumber}
          onChange={formik.handleChange("phoneNumber")}
          onBlur={formik.handleBlur("phoneNumber")}
          label="Phone Number"
          sx={commonStyles}
        />

        <Typography sx={{ color: "#fc0303", fontFamily: "Montserrat" }}>
          {formik.touched.message && formik.errors.message}
        </Typography>
        <Textarea
          value={formik.values.message}
          onChange={formik.handleChange("message")}
          onBlur={formik.handleBlur("message")}
          minRows={4}
          placeholder="Your Message"
          sx={{
            fontFamily: "Montserrat",
            fontSize: { xs: 12, md: 16 },
            backgroundColor: "#f7f7f7",
            border: "none",
            outline: "none",
            boxShadow: "none",
            "& fieldset": {
              border: "none",
            },
            "&::placeholder": {
              fontFamily: "Montserrat",
            },
            "&:focus": {
              border: "none",
              outline: "none",
              boxShadow: "none",
            },
            "&:focus-visible": {
              outline: "none",
            },
          }}
        />

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            mt: 4,
          }}
        >
          <Button
            type="submit"
            sx={{
              width: "70px",
              backgroundColor: "#4288F8",
              color: "#ffffff",
              fontFamily: "Montserrat",
              p: 4,
              px: 10,
              textTransform: "capitalize",
              fontSize: "16px",
              "&:hover": {
                backgroundColor: "#4288F8",
              },
            }}
          >
            Send
          </Button>
        </Box>
      </FormControl>
    </Box>
  );
};

export default Form;
