import React, { useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { relevantbookData } from "utils/consts";

export default function RelevantBooks() {
  const [visibleBooks, setVisibleBooks] = useState(2);
  const handleViewMore = () => {
    setVisibleBooks(relevantbookData.length);
  };
  const hasMoreBooks = visibleBooks < relevantbookData.length;
  return (
    <Grid container>
      {relevantbookData.slice(0, visibleBooks).map((book) => (
        <React.Fragment key={book.id}>
          <Grid item sm={12} lg={6} xl={4}>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <img
                  src={book.image} // Dynamically load image source from JSON
                  alt={book.title}
                  style={{ width: "80%" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "4px",
                    textTransform: "none",
                    mt: 2,
                    backgroundColor: "#FE982D",
                    height: {
                      xs: "48px",
                      sm: "68px",
                      md: "68px",
                      lg: "68px",
                      xl: "68px",
                    },

                    width: "80%",
                    color: "white",
                  }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "18",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "600",
                    }}
                    lineHeight="34px">
                    Buy Now
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} lg={6} xl={8}>
            <Grid
              container
              sx={{
                paddingLeft: "5px",
                marginTop: {
                  xs: "30px",
                  sm: "30px",
                  md: "30px",
                  lg: "0px",
                  xl: "0px",
                },
              }}>
              <Grid item xs={12}>
                <Typography
                  fontFamily="Montserrat"
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "24px",
                    },
                    fontWeight: "600",
                  }}
                  lineHeight="40px">
                  {book.title}
                </Typography>
                {book.year && book.author && (
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                      fontWeight: "500",
                    }}
                    letterSpacing="10%"
                    lineHeight="40px">
                    <span style={{ fontWeight: "600" }}>Year: </span>
                    {book.year} |{" "}
                    <span style={{ fontWeight: "600" }}> Author:</span>{" "}
                    {book.author}
                  </Typography>
                )}
                {book.synopsis && (
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                      fontWeight: "500",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    <span style={{ fontWeight: "600" }}>Synopsis: </span>{" "}
                    {book.synopsis}
                  </Typography>
                )}
              </Grid>
              {book.highlights && (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    Key Highlights-
                  </Typography>
                  <Grid>
                    <ol style={{ fontFamily: "Montserrat", fontWeight: "600" }}>
                      {book.highlights.map((highlight, idx) => (
                        <li key={idx}>
                          <Typography
                            fontFamily="Montserrat"
                            sx={{
                              fontSize: {
                                xs: "16px",
                                sm: "16px",
                                md: "16px",
                                lg: "16px",
                              },
                              fontWeight: "500",
                            }}
                            lineHeight="34px">
                            <span style={{ fontWeight: "600" }}>
                              {highlight.title}:
                            </span>
                            {highlight.description}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                </Grid>
              )}
              {book.actresses && (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    Notable Actresses Featured-
                  </Typography>
                  <Grid container sx={{ marginTop: "20px" }}>
                    {book.actresses.map((actress, idx) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        lg={4}
                        xl={4}
                        key={idx}
                        sx={{ marginTop: "10px" }}>
                        <Grid container spacing={2}>
                          <Grid item xl={4}>
                            <img
                              src={actress.image}
                              alt={actress.name}
                              style={{}}
                            />
                          </Grid>
                          <Grid item xl={8}>
                            <Typography
                              fontFamily="Montserrat"
                              sx={{
                                fontSize: {
                                  xs: "16px",
                                  sm: "16px",
                                  md: "16px",
                                  lg: "16px",
                                },
                                fontWeight: "600",
                              }}
                              lineHeight="34px">
                              {actress.name}
                            </Typography>
                            <Typography
                              fontFamily="Montserrat"
                              sx={{
                                fontSize: {
                                  xs: "14px",
                                  sm: "14px",
                                  md: "14px",
                                  lg: "14px",
                                },
                                fontWeight: "500",
                              }}
                              lineHeight="23px">
                              {actress.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {/* themes */}
              {book.themes && (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    Themes-
                  </Typography>
                  <Grid>
                    <ol style={{ fontFamily: "Montserrat", fontWeight: "600" }}>
                      {book.themes.map((highlight, idx) => (
                        <li key={idx}>
                          <Typography
                            fontFamily="Montserrat"
                            sx={{
                              fontSize: {
                                xs: "16px",
                                sm: "16px",
                                md: "16px",
                                lg: "16px",
                              },
                              fontWeight: "500",
                            }}
                            lineHeight="34px">
                            <span style={{ fontWeight: "600" }}>
                              {highlight.title}:
                            </span>
                            {highlight.description}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                </Grid>
              )}
              {/* endthemes */}
              {/* notable sections */}
              {book.notable_sections && (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    Notable Sections-
                  </Typography>
                  <Grid>
                    <ol style={{ fontFamily: "Montserrat", fontWeight: "600" }}>
                      {book.notable_sections.map((notable_section, idx) => (
                        <li key={idx}>
                          <Typography
                            fontFamily="Montserrat"
                            sx={{
                              fontSize: {
                                xs: "16px",
                                sm: "16px",
                                md: "16px",
                                lg: "16px",
                              },
                              fontWeight: "500",
                            }}
                            lineHeight="34px">
                            <span style={{ fontWeight: "600" }}>
                              {notable_section.title}:
                            </span>
                            {notable_section.description}
                          </Typography>
                        </li>
                      ))}
                    </ol>
                  </Grid>
                </Grid>
              )}
              {/* notablesections */}
              {book.conclusion && (
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "18px",
                        lg: "18px",
                      },
                      fontWeight: "700",
                    }}
                    letterSpacing="10%"
                    lineHeight="34px">
                    Conclusion-
                  </Typography>
                  <Typography
                    fontFamily="Montserrat"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "16px",
                        lg: "16px",
                      },
                      fontWeight: "500",
                    }}
                    lineHeight="34px">
                    {book.conclusion}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Divider sx={{ margin: "30px 0", width: "100%" }} />
        </React.Fragment>
      ))}
      {hasMoreBooks && (
        <Grid
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Button
            variant="contained"
            onClick={handleViewMore}
            sx={{
              borderRadius: "44px",
              textTransform: "none",
              mt: 2,
              backgroundColor: "#ECECEC",
              height: "70px",

              height: {
                xs: "48px",
                sm: "68px",
                md: "70px",
                lg: "70px",
                xl: "70px",
              },
              width: "150px",
              color: "black",
              "&:hover": {
                backgroundColor: "#ECECEC", // Keeps the background color the same
                filter: "none", // Ensures no blur or other filters are applied
              },
            }}>
            <Typography
              fontFamily="Montserrat"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "18",
                  md: "18px",
                  lg: "18px",
                },
                fontWeight: "500",
              }}
              lineHeight="34px">
              View More
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
