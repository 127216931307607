import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import persoanl from "../../../assets/images/skt/personal.png";
import legacy from "../../../assets/images/skt/legacy.png";

const LegacyCard = () => {
  return (
    <Grid container>
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: { xs: "wrap", sm: "nowrap" },
          alignItems: "start",
          justifyContent: "center",
          gap: { xs: 2, sm: 4, md: 6 },
          mt: {xs:4, sm:8, md:12},
          px: { xs: 4, sm:6, md: 12 },
        }}
      >
        <Box sx={{ width: { md: "600px" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 0, md: 2 },
              textTransform: "uppercase",
            }}
          >
            Personal Life
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
              mb: 2,
            }}
          >
            Sreekumaran Thampi is known for his humble and down-to-earth
            personality. He remains a source of inspiration for aspiring writers
            and filmmakers. Despite his fame, he has always maintained a close
            connection with his roots and continues to contribute to the
            cultural and literary landscape of Kerala.
          </Typography>
          <Box sx={{ xs: "330px", md: "600px" }}>
            <img src={persoanl} alt="" width="100%" />
          </Box>
        </Box>
        <Box sx={{ width: { md: "600px" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 0, md: 2 },
              textTransform: "uppercase",
            }}
          >
            Legacy
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
              mb: 2,
            }}
          >
            Sreekumaran Thampi's legacy in the Malayalam film industry and
            literature is immense. His works continue to inspire and entertain
            audiences, and his contributions have enriched the cultural heritage
            of Kerala. He remains an iconic figure whose creative genius has
            left an indelible mark on the arts.
          </Typography>
          <Box sx={{ xs: "330px", md: "600px",  mt: {xs:0, sm: 4, md:0 } }}>
            <img src={legacy} alt="" width="100%" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LegacyCard;
