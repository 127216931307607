import { Grid, Typography } from "@mui/material";
import React from "react";
import Form from "./Form";

const GetInTouch = () => {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: { xs: "column", sm: "column", md: "row" },
        alignItems: "start",
        justifyContent: "space-between",
        gap: { xs: 2, sm: 4, md: 5 },
        mt: { xs: 2, sm: 4, md: 6 },
        px: { xs: 4, sm: 6, md: 12 },
      }}
    >
      <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
        <Typography
          fontFamily="TT Norms Pro Serif"
          sx={{
            fontSize: { xs: "14px", sm: "16px", md: "20px" },
            fontWeight: 600,
            my: { xs: 1, sm: 0, md: 2 },
            textTransform: "uppercase",
          }}
        >
          We’d love to hear from you
        </Typography>
        <Typography
          fontFamily="Montserrat"
          sx={{
            fontSize: { xs: "12px", md: "14px" },
            fontWeight: 400,
            lineHeight: { xs: "24px", sm: "30px", md: "36px" },
            textAlign: "justify",
          }}
        >
          This is meant to be an online meeting place for everyone around the
          world who has grown up watching Sreekumaran Thambi's films, loves the
          history of Malayalam cinema, or is seeking reliable information on
          Sreekumaran Thambi to learn more about him.
        </Typography>
        {/* <Typography
          fontFamily="Montserrat"
          sx={{
            fontSize: { xs: "12px", md: "14px" },
            fontWeight: 400,
            lineHeight: { xs: "24px", sm: "30px", md: "36px" },
            textAlign: "justify",
          }}
        >
          Ullam facilisis ex ut velit malesuada, vel aliquam sem dictum. Ut sit
          amet malesuada lorem. Fusce venenatis, nulla id lacinia placerat, ex
          purus pharetra felis, non sagittis arcu metus et purus. Mauris
          tincidunt vestibulum turpis, in gravida mi vestibulum a. In sed risus
          non lacus tristique convallis et a purus
        </Typography> */}
      </Grid>
      <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
      <Typography
          fontFamily="TT Norms Pro Serif"
          sx={{
            fontSize: { xs: "16px", sm: "18px", md: "20px" },
            fontWeight: 600,
            my: { xs: 1, sm: 0, md: 2 },
            textTransform: "uppercase",
          }}
        >
          Enquiry Form
        </Typography>
          <Form/>
      </Grid>
    </Grid>
  );
};

export default GetInTouch;
