import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import CareerCard from "./CareerCard";
import LegacyCard from "./LegacyCard";
import LyricsCard from "./LyricsCard";
import Script from "./Script";
import Contribution from "./Contribution";
import Quates from "./Quates";

const SreeKumaranThambi = () => {
  return (
    <Grid container>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#F5F4ED",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 3,
        }}
      >
        <Typography
          fontFamily="Tiempos Headline"
          sx={{
            fontSize: { xs: "18px", sm: "24px", md: "28px", lg: "30px" },
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        >
          About Sreekumaran Thampi
        </Typography>
      </Box>
      <CareerCard />
      <LyricsCard />
      <Script />
      <Quates />
      <Contribution />
      <LegacyCard />
    </Grid>
  );
};

export default SreeKumaranThambi;
