import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import early from "../../../assets/images/skt/early.png";
import career from "../../../assets/images/skt/career.png";

const CareerCard = () => {
  return (
    <Grid container >
      <Grid
        item
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: { xs: "wrap", sm: "nowrap" },
          alignItems: "start",
          justifyContent: "center",
          gap: { xs: 2, sm: 4, md: 6 },
          mt: {xs:2, sm:4, md:6},
          px: { xs: 4, sm:6, md: 12 },
        }}
      >
        <Box sx={{ width: { md: "600px" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 0, md: 2 },
              textTransform: "uppercase",
            }}
          >
            Early Life& Education
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
              mb: 2,
            }}
          >
            Sreekumaran Thampi was born on March 16, 1940, in Haripad, a small
            town in the Alappuzha district of Kerala, India. His early education
            was at Haripad, and he later attended University College in
            Thiruvananthapuram, where he completed his degree in Mathematics.
            Thampi's passion for literature and the arts was evident from a
            young age, and he was actively involved in writing and cultural
            activities during his school and college days.
          </Typography>
          <Box sx={{ xs: "330px", md: "600px" }}>
            <img src={early} alt="" width="100%" />
          </Box>
        </Box>
        <Box sx={{ width: { md: "600px" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 0, md: 2 },
              textTransform: "uppercase",
            }}
          >
            Career in Film Industry
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
              mb: 2,
            }}
          >
            Sreekumaran Thampi is a multifaceted personality in the Malayalam
            film industry, known for his work as a lyricist, scriptwriter,
            director, and producer. He began his career in the film industry in
            the early 1970s and quickly gained prominence for his exceptional
            talent in writing lyrics and screenplays. His contributions in all
            these fields are substantial, and so, the recognition in the form of
            JC Daniel Award for Lifetime Achievement could only be described as
            ‘better late than never’.
          </Typography>
          <Box sx={{ xs: "330px", md: "600px", mt: {xs:0, sm: 4, md:0 } }}>
            <img src={career} alt="" width="100%" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CareerCard;
