import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import script from "../../../assets/images/skt/script.png";

const movies = [
    " 1. Gaanam",
    " 2. Mohiniyattam",
    " 3. Sarapancharam",
    " 4. Raagam",
    " 5. Naayattu"
  ];

const Script = () => {
  return (
    <Box sx={{ px: { xs: 4, sm:6, md: 15 },  mt: {xs:4, sm:8, md:12} }}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Grid item sx={{ width: { xs: "100%", md: "48%" }, }}>
          <img src={script} alt="" width="100%"/>
        </Grid>
        <Grid item sx={{ width: { xs: "100%", md: "48%" } }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: 600,
              my: { xs: 1, sm: 2, md:0 },
              textTransform: "uppercase",
            }}
          >
            Scriptwriter and Director
          </Typography>
          <Typography
            fontFamily="Montserrat"
            sx={{
              fontSize: { xs: "12px",  md: "14px" },
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "30px", md: "36px" },
              textAlign: "justify",
            }}
          >
            As a scriptwriter, Thampi has been instrumental in creating numerous
            hit films that are celebrated for their compelling narratives and
            well-developed characters. He ventured into directing with the movie
            *Chattambikkavala* in 1969. Over the years, he directed several
            successful films, including:
          </Typography>
          {movies.map((movie) => (
            <Typography
              fontFamily="Montserrat"
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" },
                fontWeight: 500,
                lineHeight: { xs: "24px", sm: "30px", md: "36px" },
                backgroundColor: "#F5F4ED",
                width: "100%",
                p: 0.5,
                mt:1
              }}
            >
              {movie}
            </Typography>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Script;
