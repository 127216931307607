import React from 'react'
import MeetTheTeam from './Members/MeetTheTeam'
import SreeKumaranThambi from './SreeKT/SreeKumaranThambi'

const AboutUs = () => {
  return (
    <div>
      <MeetTheTeam/>
      <SreeKumaranThambi/>
    </div>
  )
}

export default AboutUs
