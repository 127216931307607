import { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./routes";

import Preloader from "components-shared/Preloader/Preloader";
import HomePage from "components-pages/HomePage/HomePage";
import Filmography from "components-pages/Filmography";
import MyBooks from "components-pages/MyBooks";
import AboutUs from "components-pages/AboutUs";
import ContactUs from "components-pages/ContactUs";
import Events from "components-pages/Events";

// const Login = lazy(() => import("components/Login"));

export default function AppRouter() {
  return (
    <Suspense fallback={<Preloader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about_us" element={<AboutUs />} />
        <Route path="/filmography" element={<Filmography />} />
        <Route path="/books" element={<MyBooks />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/events" element={<Events />} />

        {/* <Route path={routes.Login} element={<Login />} /> */}
      </Routes>
    </Suspense>
  );
}
