import React from "react";
import { Grid, Typography } from "@mui/material";
import image from "../../../assets/images/homepage/about.png";

export default function About() {
  return (
    <Grid
      container
      sx={{
        width: "100%",
        p: { xs: 4, md: 10 },
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Grid
        item
        sx={{
          width: { md: "33%", xs: "100%" },
          px: { xs: 1, md: 3 },
          mt: { xs: 2 },
        }}
      >
        <img src={image} alt="" width="100%" />
      </Grid>
      <Grid
        item
        sx={{ width: { md: "33%", xs: "100%" }, px: { xs: 1, md: 3 } }}
      >
        <Typography
          fontFamily="TT Norms Pro Serif"
          sx={{
            fontSize: { xs: 20, md: 30, fontWeight: 600 },
            mt: { xs: 2 },
            textTransform: "uppercase",
          }}
        >
          Sreekumaran Thampi Foundation
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="36px"
          textAlign='justify'
          sx={{ fontSize: { xs: 12, md: 14 }, mt: { xs: 2 } }}
        >
          The primary mission of the Sreekumaran Thampi Foundation is to foster
          a vibrant cultural landscape and support the underprivileged sections
          of society through various initiatives.
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          width: { md: "33%", xs: "100%" },
          px: { xs: 1, md: 3 },
          mt: { xs: 2 },
        }}
      >
        <Typography
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
          textAlign='justify'
          sx={{ fontSize: { xs: 12, md: 14 } }}
        >
          The Sreekumaran Thampi Foundation is a charitable organization
          established to honor the legacy and contributions of the renowned
          Malayalam film industry personality, Sreekumaran Thampi. 
        </Typography>
        <Typography
          fontFamily="Montserrat"
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
          textAlign='justify'
          sx={{ fontSize: { xs: 12, md: 14 } }}
        >
          The
          foundation aims to promote and support various cultural, educational,
          and social initiatives that reflect the values and vision of
          Sreekumaran Thampi.
        </Typography>
      </Grid>
    </Grid>
  );
}
