import style from "./ContactBanner.module.scss";

import { Box, createTheme, ThemeProvider, Typography } from "@mui/material";
const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1000,
    lg: 1280,
  },
};

const theme = createTheme({
  breakpoints: { ...breakpoints },
});

const ContactBanner = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.deskTopWrapper} sx={{ height: "280px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            py: { xs: 18 },
          }}
        >
          <Typography
            fontFamily="Tiempos Headline"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: "600",
              textAlign: "center",
              textTransform: "uppercase",
              mt: 4,
              color: "#FFFFFF",
            }}
          >
            Get in Touch
          </Typography>
        </Box>
      </Box>

      <Box className={style.tabletWrapper} sx={{ minHeight: "240px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            py: { xs: 18 },
          }}
        >
          <Typography
            fontFamily="Tiempos Headline"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: "600",
              textAlign: "center",
              textTransform: "uppercase",
              mt: 4,
              color: "#FFFFFF",
            }}
          >
            Get in Touch
          </Typography>
        </Box>
      </Box>

      <Box className={style.responsiveWrapper} sx={{ height: "220px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            py: { xs: 18 },
          }}
        >
          <Typography
            fontFamily="Tiempos Headline"
            sx={{
              fontSize: { xs: "18px", sm: "24px", md: "30px" },
              fontWeight: "600",
              textAlign: "center",
              textTransform: "uppercase",
              mt: 4,
              color: "#FFFFFF",
            }}
          >
            Get in Touch
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ContactBanner;
