import React from "react";
import Banner from "./Banner/Banner";
import Books from "./Books/Books";
import LaunchEvent from "./Launch/LaunchEvent";
import About from "./About/About";
import Foundation from "./Foundation/Foundation";

const HomePage = () => {
  return (
    <div>
      <Banner />
      <About />
      <LaunchEvent />
      <Foundation />
      <Books />
    </div>
  );
};

export default HomePage;
