import React, { useRef } from "react";

import styles from "./Events.module.scss";

import { Box, Grid, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import EventCard from "./EventCard/EventCard";
import { eventData } from "utils/eventdata";

import swiperimg from "../../assets/images/events/swiper1.png";
import event2 from "../../assets/images/events/event2.png";
import event3 from "../../assets/images/events/event3.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Autoplay } from "swiper/modules";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Events() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    display: "none",
  };

  return (
    <div style={{ marginBottom: "80px" }}>
      <div className={styles.banner}>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}>
          <Typography
            fontFamily="TT Norms Pro Serif"
            sx={{
              fontSize: { xs: "30px", sm: "20px", md: "28px", lg: "30px" },
              fontWeight: "500",
              textAlign: {
                xs: "center",
                sm: "center",
                md: "inherit",
                lg: "inherit",
              },
              color: "#FFFFFF",
            }}
            letterSpacing="10%"
            lineHeight="45.8px">
            EVENTS
          </Typography>
        </Grid>
      </div>
      <div
        style={{
          marginLeft: "8%",
          marginRight: "8%",
          marginTop: "100px",
        }}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{ width: "100%", px: { xs: 4, sm: 4, md: 6, lg: 8 }, mt: 4 }}>
              <Box
                sx={{
                  backgroundColor: "#F8F8F8",
                  borderRadius: 3,
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                  TabIndicatorProps={{ style: indicatorStyles }}
                  textColor="#ffffff"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}>
                  <Tab
                    label="Upcoming Events"
                    {...a11yProps(0)}
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "20px",
                        lg: "20px",
                      },
                      textTransform: "none",
                      fontFamily: "Montserrat",
                      textAlign: "center",
                      fontWeight: 600,
                      color: value === 0 ? "#000000" : "#656565",
                      backgroundColor: value === 0 ? "#FFFFFF" : "#F6F6F6",
                      borderRadius: 3,
                      padding: "5px",
                      width: "300px",
                      padding: "16px 32px 24px 8px",
                    }}
                  />
                  <Tab
                    label="Previous events"
                    {...a11yProps(1)}
                    sx={{
                      fontSize: {
                        xs: "16px",
                        sm: "16px",
                        md: "20px",
                        lg: "20px",
                      },
                      textTransform: "none",
                      fontFamily: "Montserrat",
                      fontWeight: 600,
                      color: value === 0 ? "#656565" : "#000000",
                      backgroundColor: value === 0 ? "#F6F6F6" : " #FFFFFF",
                      borderRadius: 3,
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container>
                  <Grid item xs={12}>
                    {eventData.map((event) => (
                      <Grid
                        item
                        xs={12}
                        key={event.id}
                        sx={{ marginTop: "20px" }}>
                        <EventCard
                          image={event.image}
                          date={event.date}
                          time={event.time}
                          heading={event.description}
                          location={event.location}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container>
                  <Grid item xs={12}>
                    {eventData.map((event) => (
                      <Grid
                        item
                        xs={12}
                        key={event.id}
                        sx={{ marginTop: "20px" }}>
                        <EventCard
                          image={event.image}
                          date={event.date}
                          time={event.time}
                          heading={event.description}
                          location={event.location}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </div>
      <div
        style={{
          marginLeft: "8%",
          marginRight: "8%",
          marginTop: "100px",
        }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Typography
              fontFamily="TT Norms Pro Serif"
              sx={{
                fontSize: { xs: "24px", sm: "24px", md: "28px", lg: "30px" },
                fontWeight: "500",
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "inherit",
                  lg: "inherit",
                },
                color: "#000000",
              }}
              lineHeight="45.8px">
              EVENTS PICTURES
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: {
                xs: "50px",
                sm: "50px",
                md: "100px",
                lg: "100px",
                xl: "100px",
              },
            }}>
            <Swiper
              navigation={true}
              loop={true}
              modules={[Navigation]}
              autoplay={{ delay: 3000 }}
              modules={[Navigation, Autoplay]}
              className="mySwiper">
              <SwiperSlide>
                <img
                  alt=" Logo"
                  src={swiperimg}
                  height="auto"
                  width="100%"
                  style={{
                    minHeight: "400px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <img
                  alt=" Logo"
                  src={event2}
                  height="auto"
                  width="100%"
                  style={{
                    minHeight: "400px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <img
                  alt=" Logo"
                  src={event3}
                  height="auto"
                  width="100%"
                  style={{
                    minHeight: "400px",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
