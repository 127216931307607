import { Box, Typography } from "@mui/material";
import React from "react";
import quoteLeft from "../../../assets/icons/quote-left.png";
import quoteRight from "../../../assets/icons/quote-right.png";
import quotes from "../../../assets/images/quotes.png";

const Quates = () => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        px: { xs: 10, sm: 24, md: 60 },
        mt: { xs: 4, sm: 8, md: 12 },
      }}
    >
      <img src={quotes} alt="" width='100%'/>
      {/* <Box sx={{ width: "100%", px: { xs: 10, sm: 24, md: 80 } }}>
        <img src={quoteLeft} alt="" style={{ marginRight: "70%" }} />
      </Box>
      <Typography
        fontFamily="Montserrat"
        sx={{
          fontSize: { xs: "14px", sm: "16px", md: "24px" },
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        His directorial works are known for their strong storytelling and
        ability to connect with the audience emotionally.
      </Typography>
      <Box sx={{ width: "100%", px: { xs: 10, sm: 24, md: 80 } }}>
        <img src={quoteRight} alt="" style={{ marginLeft: "70%" }} />
      </Box> */}
    </Box>
  );
};

export default Quates;
